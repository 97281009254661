<template>
  <div>
    <tableComponent
      :data="$data.templates"
      :columns="['client', 'project', 'custom_columns', 'actions']"
      :filter-exclude-columns="['actions']"
      ref="table"
    >
      <template slot="client" slot-scope="{ row }">
        <div class="VerticalAlign centerAlign">{{ row.client_name || "" }}</div>
      </template>
      <template slot="project" slot-scope="{ row }">
        <div class="VerticalAlign centerAlign">
          {{ row.project_name || "" }}
        </div>
      </template>
      <template slot="custom" slot-scope="{ row }">
        <div class="VerticalAlign centerAlign">
          {{ row.custom_columns || "" }}
        </div>
      </template>
      <template slot="actions" slot-scope="{ row }" class="d-flex gap-1">
        <div class="d-flex gap-1">
          <CButton
            @click="() => anonRow(row)"
            color="primary"
            variant="outline"
            v-c-tooltip="$t('Randomize data')"
          >
            <i class="fa fa-eye-slash"></i>
          </CButton>
          <!-- <CButton
              @click="() => {handleConfirmDelete(row); deletable_row = row}"
              color="danger"
              variant="outline"
              v-c-tooltip="$t('Delete data')"
            >
              <i class="fa fa-trash-o" />
            </CButton> -->
        </div>
      </template>
    </tableComponent>

    <table class="table table-sm">
      <thead class="header">
        <!-- <tr>
                <th rowspan="2" v-for="h in ['Column name in the database', 'Type', 'Only unique values', 'Approach', 'Randomization Min', 'Randomization Max', 'Level Top', 'Level Bottom', 'Can be negative']">{{h}}</th>
              </tr> -->
        <tr>
          <th rowspan="2">Column name in the database</th>
          <th rowspan="2">Type</th>
          <th rowspan="2">Only unique values</th>
          <th rowspan="2">Approach</th>
          <th colspan="2">Randomization</th>
          <th colspan="2">Level</th>
          <th rowspan="2">Can be negative</th>
        </tr>
        <tr>
          <th>Min</th>
          <th>Max</th>
          <th>Bottom</th>
          <th>Top</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="column in this.fileData">
          <!-- <td class="align-middle text-center">
                    <input type="checkbox" id="checkbox" v-model="column[0]"/>
                </td> -->
          <!-- <td class="align-middle">{{ column[1] }}</td> -->
          <td class="align-middle">
            <CInput type="text" class="name1" :value.sync="column[0]" />
          </td>
          <td>
            <CSelect
              class="Dtype"
              :value.sync="column[1]"
              :options="['Integer', 'Float', 'String', 'Date']"
            ></CSelect>
          </td>
          <td class="align-middle text-center">
            <input type="checkbox" id="checkbox" v-model="column[2]" />
          </td>
          <td>
            <CSelect
              class="Dtype"
              :value.sync="column[3]"
              :options="['Integer', 'Float', 'String', 'Date']"
            ></CSelect>
          </td>
          <td class="align-middle">
            <CInput
              :value.sync="column[4]"
              type="number"
              class="input-number"
            />
          </td>
          <td class="align-middle">
            <CInput
              :value.sync="column[5]"
              type="number"
              class="input-number"
            />
          </td>
          <td class="align-middle">
            <CInput
              :value.sync="column[6]"
              type="number"
              class="input-number"
            />
          </td>
          <td class="align-middle">
            <CInput
              :value.sync="column[7]"
              type="number"
              class="input-number"
            />
          </td>
          <td class="align-middle text-center">
            <input type="checkbox" id="checkbox" v-model="column[8]" />
          </td>

          <!-- <td class="align-middle" v-if="column[3]=='Date'">
                  <CInput
                        :value.sync="column[4]"
                        class="FormatInput"
                    ></CInput>
                </td>
                <td class="align-middle" v-if="showFormat && column[3]!='Date'"></td>
                <td class="align-middle Comment" v-if="showComments && comments[column[1]]">
                  {{comments[column[1]]}}
                </td>
                <td class="align-middle" v-if="!comments[column[1]] && showComments"></td> -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ListsService } from "@/services/lists";
import { AnonymizeService } from "@/services/anonymize";
// import { TemplatesService } from '@/services/templates';
// import InvoiceTable from '../components/InvoiceTable.vue';
// import { CModal } from '@mz/coreui-vue';
// import ConfirmationModal from "../components/ConfirmationModal.vue";

export default {
  name: "Anonymize",
  // components: { InvoiceTable, CModal, ConfirmationModal },
  data() {
    return {
      fileData: [["Name", "String", true, "String", -5, 5, -15, 15, true]],
      templates: null,
      // editVisible: false,
      // confirmEditVisible: false,
      // editable_row: null,
      // data_for_edit: null,
      // confirmDelete: false,
      // currentRow: {},
      // deletable_row: null,
      // all_checked: false,
      // prev_ver_templ: null
    };
  },

  methods: {
    async getTemplates() {
      var { data, res } = await ListsService.get_list({
        collection: "templates",
        filter_off: false,
      });
      if (res.status !== 200) {
        return alert("Please check your internet connetion or log in");
      }
      if (data.success) {
        this.templates = data.data
          .map(({ id, ...keepAttrs }) => keepAttrs)
          .reverse();
        this.templates = this.templates.map((a) => {
          a.custom_columns = a.custom_columns.map((b) => b[0]).join(", ");
          return a;
        });
      }
    },

    async anonRow(row) {
      var { data, res } = await AnonymizeService.process({ template: row });
      if (res.status !== 200) {
        return alert("Please check your internet connetion or log in");
      }
      if (data.success) {
        this.$toasted.show("Your data was randomized!", {
          position: "bottom-right",
          duration: 5000,
        });
      }
    },
  },

  created() {
    this.getTemplates();
  },
};
</script>

<style>
.name1 {
  min-width: 150px;
}
.input-number {
  width: 70px;
}
.Dtype {
  margin-top: 12px;
  min-width: 100px;
}
th {
  vertical-align: middle !important;
  text-align: center;
}
td {
  vertical-align: middle !important;
}
.FormatInput {
  width: 7rem;
}
.CheckButton {
  margin: 1rem 0 1rem auto;
  height: 4em;
  width: 10em;
  background-color: #636f83;
  color: white;
  float: right;
}
.Comment {
  max-width: 50px;
}
.header {
  background-color: #0071ce;
  color: white;
}
.mazarsButton {
  float: right;
  align-items: center;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  border-radius: 0;
  border-bottom-left-radius: 1.75rem;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  max-width: 100%;
  min-height: 3rem;
  min-width: 200px;
  overflow: hidden;
  padding: 0.75rem 1.5rem;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 30px;
}
.mr0 {
  margin-right: 0;
}
.spinner-border {
  height: 3rem;
  width: 3rem;
  animation-duration: 2s;
  margin-left: calc(50% - 1.5rem);
  margin-bottom: 30px;
  position: relative;
}
.CheckSwitch {
  display: flex;
  margin-bottom: 10px;
}
.CheckText {
  margin-left: 7px;
  margin-top: 2px;
  display: flex;
}
</style>
